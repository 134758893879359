
import * as storyblokRuntime$nkXzRYYqd2 from '/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as strapiRuntime$uDpxwQtF4I from '/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/strapi'
import * as ipxRuntime$GRFKGqlXI6 from '/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$nkXzRYYqd2, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['strapi']: { provider: strapiRuntime$uDpxwQtF4I, defaults: {"baseURL":"https://api.recruiting.coop.ch"} },
  ['ipx']: { provider: ipxRuntime$GRFKGqlXI6, defaults: {} }
}
        