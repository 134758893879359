<template>
  <div class="provider-form-questions">
    <UtilRendererForm
      v-if="fields?.length > 0"
      :fields="fields"
      :submit-text="submitText"
      :success-redirect="successRedirect?.cached_url"
      :error-message="errorMessage"
      :callback="submitCallback"
    />
  </div>
</template>

<script setup>
defineProps({
    /*
        Form
    */
    fields: {
        type: Array,
        default: () => [],
    },

    successRedirect: {
        type: [Object],
        default: null,
    },

    errorMessage: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },
});

const recruiter = inject('recruiter', {});
const { locale } = useI18n();
const { create } = useStrapi();

// write data into cookie
const userData = useCookie('user_data', {
    maxAge: 60 * 60 * 24 * 30,
});

const questionId = useCookie('question_id', {
    maxAge: 60 * 60 * 24 * 30,
});

const submitCallback = async (data) => {
    userData.value = data;

    const body = {
        ...data,
        recruiter: recruiter.value.id,
        answered: false,
        language: locale.value,
        // always set reminder to false cause this is currently not used anymore
        reminder: false,
    };

    const question = await create('questions', body);
    questionId.value = question.data.id;
};
</script>
