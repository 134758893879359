<template>
  <button
    ref="wrapperRef"
    class="atom-text-tooltip"
    type="button"
    :class="[
      `is-position-x-${positionX}`,
      `is-position-y-${positionY}`,
      { 'is-visible': isVisible },
    ]"

    @click="toggleTooltip"
  >
    <IonIcon
      :icon-name="icon"
      :icon-size="iconSize"
      :icon-color="iconColor"
    />

    <div class="atom-text-tooltip__text">
      {{ text }}
      <div class="atom-text-tooltip__triangle" />
    </div>
  </button>
</template>

<script setup>
const props = defineProps({
    /*
        Content
    */
    text: {
        type: String,
        default: '',
    },

    /*
        Layout
    */
    positionX: {
        type: String,
        default: 'center',
    },

    positionY: {
        type: String,
        default: 'bottom',
    },

    /*
        Styles
    */
    borderRadius: {
        type: String,
        default: 'var(--b-radius--big)',
    },

    iconColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    textColor: {
        type: String,
        default: 'var(--c-black)',
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-white)',
    },

    icon: {
        type: String,
        default: 'information-circle',
    },

    iconSize: {
        type: String,
        default: '20px',
    },
});

const {
    backgroundColor,
    textColor,
    borderRadius,
} = toRefs(props);

/*
      Visibility
  */
const isVisible = ref(false);
const toggleTooltip = () => {
    isVisible.value = !isVisible.value;
};

/* Close tooltip on click outside */
const wrapperRef = ref(null);

onClickOutside(wrapperRef, () => {
    if (isVisible.value) {
        toggleTooltip();
    }
});

</script>

<style lang="scss" scoped>
.atom-text-tooltip {
    @include z-index('tooltip');

    position: relative;
    display: inline-block;
}

.atom-text-tooltip__text {
    @include fluid('width', 189px, 198px, 700px, false);

    position: absolute;
    padding: 20px;
    border: 1px solid var(--c-black);
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    color: v-bind(textColor);
    font-family: var(--f-family--secondary);
    font-size: var(--f-size--description);
    line-height: var(--l-height--description);
    opacity: 0;
    transform-origin: top left;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: hidden;

    .is-visible & {
        opacity: 1;
        visibility: visible;
    }

    .is-position-y-top &{
        bottom: 35px;
    }

    .is-position-y-bottom &{
        top: 35px;
    }

    .is-position-x-left &{
        right: -18px;
    }

    .is-position-x-center &{
        right: -18px;

        @include tablet {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .is-position-x-right &{
        left: -18px;
    }
}

.atom-text-tooltip__triangle {
    position: absolute;
    left: -1px;
    overflow: visible;
    width: 17px;
    height: 17px;
    border-radius: 1px;
    background-color: v-bind(backgroundColor);
    transform: rotate(45deg);

    .is-position-y-top &{
        bottom: -9px;
        border-right: 1px solid var(--c-black);
        border-bottom:  1px solid var(--c-black);
    }

    .is-position-y-bottom &{
        top: -9px;
        border-top:  1px solid var(--c-black);
        border-left: 1px solid var(--c-black);
    }

    .is-position-x-left &{
        right: 16px;
        left: unset;
    }

    .is-position-x-center &{
        right: 18px;
        left: unset;

        @include tablet {
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
    }

    .is-position-x-right &{
        left: 18px;
    }

    &.position-y-bottom {
        bottom: -11px;
    }
}
</style>
