<template>
  <form
    ref="formRef"
    class="util-controller-form"
    :class="{
      'is-on-grid': isOnGrid,
      'is-inline': isInline,
    }"
    @submit.prevent="onSubmit"
  >
    <div class="util-controller-form__inner">
      <div class="util-renderer-form__fields">
        <component
          :is="useComponent(field.component)"
          v-for="field of fields"
          ref="fieldRefs"
          v-bind="{ ...buildProps(field) }"
          :key="field._uid"
          v-editable="field"
          class="util-renderer-form__field"
          :class="`is-field-width-${field.width}`"
          @set-input="onSetInput"
          @set-error="onSetErrors"
          @on-reset="hasErrors = true"
        />
      </div>

      <div
        v-if="resetText || submitText"
        class="util-renderer-form__controls"
        :class="[`is-button-alignment-${buttonAlignment}`]"
      >
        <AtomButton
          v-if="resetText"
          class="util-renderer-form__control"
          button-type="reset"
          :text="resetText"
          @click="onReset()"
        />

        <AtomButton
          v-if="submitText"
          class="util-renderer-form__control"
          button-type="submit"
          :text="submitText"
          :is-disabled="hasErrors || isDisabled || isSending"
        />
      </div>
    </div>

    <ClientOnly>
      <Teleport to="body">
        <Transition name="fade-in">
          <AtomPopoverNotification
            v-if="popupVisible"
            position="fixed"
            :text="popupMessage"
            :type="popupType"
            @on-hide="popupVisible = false"
          />
        </Transition>
      </Teleport>
    </ClientOnly>
  </form>
</template>

<script setup>
const props = defineProps({
    fields: {
        type: Array,
        default: () => [],
    },

    /*
        Buttons
    */
    submitText: {
        type: String,
        default: '',
    },

    resetText: {
        type: String,
        default: '',
    },

    buttonColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    buttonTextColor: {
        type: String,
        default: 'var(--c-white)',
    },

    buttonBorderColor: {
        type: String,
        default: 'var(--c-primary)',
    },

    /*
        Layout
    */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    isInline: {
        type: Boolean,
        default: false,
    },

    buttonAlignment: {
        type: String,
        default: 'right',
    },

    /*
        General
    */
    isDisabled: {
        type: Boolean,
        default: false,
    },

    hasReset: {
        type: Boolean,
        default: false,
    },

    /*
        Error Messages
    */
    successRedirect: {
        type: [String, Object],
        default: null,
    },

    errorMessage: {
        type: String,
        default: '',
    },

    /*
    Callback
    */
    callback: {
        type: Function,
        default: async () => { console.log('no callback passed'); },
    },

    // If you want to use emits instead of callback
    useEmits: {
        type: Boolean,
        default: false,
    },
});

const {
    callback,
    errorMessage,
    successRedirect,
} = toRefs(props);

/*
    Variables
*/
const formRef = ref(null);
const collectedErrors = ref({});
const collectedData = ref({});

const popupVisible = ref(false);
const popupType = ref('error');
const popupMessage = ref('');

const hasErrors = ref(true);

watchEffect(() => {
    hasErrors.value = Object.keys(collectedErrors.value).length > 0;
});

/*
    Form Handling
*/
const emit = defineEmits(['on-set-input']);
const onSetInput = (data) => {
    collectedData.value[data.name] = data.value;
    if (props.useEmits) {
        emit('on-set-input', collectedData.value);
    }
};

const onSetErrors = (data) => {
    if (data.errors?.length === 0) {
        delete collectedErrors.value[data.key];
        return;
    }

    collectedErrors.value[data.key] = data.errors;
};

const fieldRefs = ref(null);
const onReset = () => {
    if (!props.hasReset) return;

    collectedData.value = {};
    formRef.value.reset();

    fieldRefs.value.forEach((field) => {
        field.resetValue();
    });
};

const isSending = ref(false);
const { translate: translateLink } = useTranslatedStoryblokLinks();
const onSubmit = async () => {
    if (hasErrors.value || Object.keys(collectedData.value).length === 0) return;

    /* Sanitize each value in collectedData.value */
    Object.keys(collectedData.value).forEach((key) => {
        if (typeof collectedData.value[key] === 'string') {
            collectedData.value[key] = sanitize(collectedData.value[key]);
        }
    });

    try {
        isSending.value = true;
        await callback.value(collectedData.value);
        isSending.value = false;

        if (successRedirect.value) {
            await navigateTo(
                translateLink(successRedirect.value),
            );
        }
    } catch (e) {
        useSentryError(e);
        popupType.value = 'error';
        popupMessage.value = errorMessage.value;
        popupVisible.value = true;
        isSending.value = false;
    }
};

/*
    Field rendering
*/
const { richTextResolver } = useStoryblokApi();

const buildProps = (data) => {
    const itemProps = { ...data };
    delete itemProps.component;
    delete itemProps._uid;

    if (data.component === 'AtomInputCheckbox') {
        itemProps.label = typeof data.label === 'object'
            ? richTextResolver.render(data.label)
            : data.label;
    }

    if (data.component === 'AtomInputTitle') {
        itemProps.text = isPolite.value
            ? itemProps.politeText || itemProps.text : itemProps.text;

        delete itemProps.politeText;
    }

    return itemProps;
};

const availableComponents = {
    AtomInputText: resolveComponent('AtomInputText'),
    AtomInputTextarea: resolveComponent('AtomInputTextarea'),
    AtomInputSelect: resolveComponent('AtomInputSelect'),
    AtomInputCheckbox: resolveComponent('AtomInputCheckbox'),
    AtomInputRadio: resolveComponent('AtomInputRadio'),
    AtomInputRating: resolveComponent('AtomInputRating'),
    AtomInputTitle: resolveComponent('AtomInputTitle'),
};

const useComponent = (key) => availableComponents[key] || null;
defineExpose({
    popupVisible,
    popupType,
    popupMessage,
});
</script>

<style lang="scss" scoped>
.util-controller-form {
    width: 100%;

    &.is-on-grid {
        @include wrapper-layout;
        @include grid-layout();
    }
}

.util-controller-form__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;

    .is-inline & {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        column-gap: 20px;
        row-gap: 20px;

        @include tablet {
            width: unset;
            flex-direction: row;
        }
    }

    .is-on-grid & {
        @include default-content-columns('wide');
    }
}

.util-renderer-form__controls {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;

    &.is-button-alignment-left {
        justify-content: flex-start;
    }

    &.is-button-alignment-right {
        justify-content: flex-end;
    }

    .is-inline & {
        width: auto;
        align-self: flex-start;

        @include tablet {
            align-self: center;
        }
    }

    @include tablet {
        flex-direction: row;
        row-gap: 0px;
    }
}

.util-renderer-form__fields {
    @include grid-layout(4, 4, 4);

    margin-bottom: 30px;
    column-gap: 20px;
    row-gap: 30px;

    .is-inline & {
        display: flex;
        flex-basis: auto;
        flex-shrink: 1;
        flex-wrap: wrap;
        align-items: flex-start;
        align-self: flex-start;
        column-gap: 20px;

        @include tablet {
            flex-wrap: unset;
        }
    }
}

.util-renderer-form__field {
    display: flex;
    flex-wrap: wrap;
    grid-column-end: span 4;

    @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
            z-index: #{100 - $i};
        }
    }

    @include tablet {
        &.is-field-width-1 {
            grid-column-end: span 1;
        }

        &.is-field-width-2 {
            grid-column-end: span 2;
        }

        &.is-field-width-3 {
            grid-column-end: span 3;
        }

        &.is-field-width-4 {
            grid-column-end: span 4;
        }
    }
}
</style>
