<template>
  <div class="block-card-information" :class="variant">
    <div class="block-card-information__top">
      <AtomTextHeading
        :text="title"
        html-tag="div"
        :font-size="getTitleSize(variant)"
        font-color="var(--c-black)"
        class="block-card-information__title"
        font-family="var(--f-family--secondary-bold)"
      />

      <AtomImage
        v-if="image.src"
        class="block-card-information__image"
        :image="image"
      />
    </div>
    <div class="block-card-information__bottom">
      <AtomTextRichtext
        v-if="text"
        class="block-card-information__text"
        :html="text"
      />
      <div v-if="isArrayNotEmpty(icons)" class="block-card-information__link-wrapper">
        <AtomLink
          v-for="icon in icons"
          :key="icon.icon"
          class="block-card-information__link"
          :icon="icon.icon"
          :link="icon.src"
          text=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    variant: {
        type: String,
        default: 'default',
    },

    title: {
        type: String,
        required: true,
    },

    titleSize: {
        type: String,
        default: 'var(--f-size--h3)',
    },

    image: {
        type: Object,
        default: () => ({}),
    },

    text: {
        type: [String, Object],
        default: '',
    },

    icons: {
        type: Array,
        default: () => [],
    },

    backgroundColor: {
        type: String,
        default: 'var(--c-grey)',
    },

    borderRadius: {
        type: String,
        default: '10px',
    },
});

const {
    backgroundColor,
    borderRadius,
} = toRefs(props);

const getTitleSize = (variant) => {
    switch (variant) {
    case 'small':
        return 'h4';
    case 'medium':
        return 'p';
    case 'large':
        return 'h3';
    default:
        return 'h3';
    }
};
</script>

<style lang="scss" scoped>
.block-card-information {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
    color: var(--c-black);

    &.default {
        @include fluid-simple('padding', 20px, 40px);
    }

    &.small {
        @include fluid-simple('padding', 20px, 20px);
    }

    .block-slider-slot & {
        height: 100%;
    }
}

.block-card-information__top {
    display: flex;
    width: 100%;

    .default & {
        @include fluid-simple('margin-bottom', 20px, 30px);
    }

    .small & {
        @include fluid-simple('margin-bottom', 10px, 20px);
    }
}

.block-card-information__title {
    flex-basis: 100%;

    &.medium {
        max-width: 100%;
    }

    .block-slider-slot & {
        font-size: 24px;
        line-height: 30px;
    }

    .block-list-cards & {
        font-size: var(--f-size--p);
        line-height: var(--l-height--p);
    }
}

.block-card-information__image {
    @include fluid-simple('flex-basis', 60px, 112px);
}

.block-card-information__text {
    ::v-deep(.atom-richtext__inner) {
        font-size: 17px;
        line-height: var(--l-height--a);
    }
}

.block-card-information__link-wrapper {
    display: flex;
    align-items: center;
    column-gap: 18px;
}

.block-card-information__link {
    ::v-deep(.ion-icon) {
        @include fluid-simple('width', 20px, 36px);
        @include fluid-simple('height', 20px, 36px);
    }
}
</style>
