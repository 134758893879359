<template>
  <ClientOnly>
    <div
      class="block-slider-slot"
      :class="{
        'is-on-grid': isOnGrid,
        'show-swiper-on-mobile': showSwiperOnMobile,
      }"
    >
      <div class="block-slider-slot__container">
        <div class="block-slider-slot__inner">
          <div
            class="block-slider-slot__swiper mobile"
          >
            <div
              v-for="(slide, index) of slides"
              :key="`mobile-slide-${index}`"
              class="block-slider-slot__slide"
            >
              <component
                :is="useComponent(slide.component)"
                v-bind="slide"
              />
            </div>
          </div>

          <div
            class="block-slider-slot__swiper-wrapper"
          >
            <ClientOnly>
              <swiper-container
                ref="swiperRef"
                class="block-slider-slot__swiper"
                v-bind="parsedSwiperOptions"
                @swiperslidechange="onSlideChange"
              >
                <swiper-slide
                  v-for="(slide, index) of slides"
                  :key="`slide-${index}`"
                  class="block-slider-slot__slide"
                >
                  <component
                    :is="useComponent(slide.component)"
                    ref="formRefs"
                    v-bind="buildProps(slide.component, slide, index === slides.length - 1)"
                    :use-emits="true"
                    @on-set-input="onSetInput($event, slide.questionIdentifier)"
                  />
                </swiper-slide>
              </swiper-container>
            </ClientOnly>
          </div>
        </div>
        <ClientOnly>
          <Teleport to="#slider-buttons-teleport-target">
            <div
              v-if="hasControls"
              class="block-slider-slot__navigation"
              :class="{ 'show-swiper-on-mobile': showSwiperOnMobile }"
            >
              <AtomButton
                class="block-slider-slot__button"
                :text="isLowerThanTabletPortrait ? '' : controlsTextPrev"
                :disabled="isDisabledPrev"
                :icon-position="'left'"
                icon="arrow-left"
                @click="slideTo('prev')"
              />
              <AtomIndicatorSteps
                v-if="showPagination && isGreaterThanMobile"
                :current-step="activeIndex"
                :steps="amountSlides"
              />
              <AtomButton
                class="block-slider-slot__button"
                :class="{ 'is-hidden': isDisabledNext }"
                :text="controlsTextNext"
                :disabled="isDisabledNext"
                @click="slideTo('next')"
              />
              <UtilRouteLink
                v-if="linkNext?.cached_url"
                class="block-slider-slot-link"
                :class="{ 'is-hidden': !isLastSlide }"
                :link="useMapStoryblokLink(linkNext, locale)"
              >
                <AtomButton
                  class="block-slider-slot__button"
                  :text="controlsTextNext"
                  @click="slideTo('next')"
                />
              </UtilRouteLink>

              <AtomButton
                v-if="hasForms && isLastSlide"
                class="block-slider-slot__button"
                :text="submitText"
                :is-disabled="isSending"
                @click="onSubmit"
              />
            </div>
          </Teleport>
        </ClientOnly>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
const props = defineProps({
    /* Layout */
    isOnGrid: {
        type: Boolean,
        default: false,
    },

    /* Content */
    slides: {
        type: Array,
        default: () => [
        ],
    },

    /* Controls */
    hasControls: {
        type: Boolean,
        default: true,
    },

    swiperOptions: {
        type: String,
        default: '{}',
    },

    controlsTextPrev: {
        type: String,
        default: 'Previous',
    },

    controlsTextNext: {
        type: String,
        default: 'Next',
    },

    linkNext: {
        type: Object,
        default: () => ({}),
    },

    showSwiperOnMobile: {
        type: Boolean,
        default: false,
    },

    // Forms
    submitCallback: {
        type: Function,
        default: null,
    },

    hasForms: {
        type: Boolean,
        default: false,
    },

    submitText: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    successRedirect: {
        type: String,
        default: null,
    },
});

const { locale } = useI18n();
const parsedSwiperOptions = JSON.parse(props.swiperOptions);

/*
  Init Swiperjs
*/
await useSwiperJs();
const swiperRef = ref(null);

const slideTo = (direction) => {
    if (!swiperRef.value) return;

    if (direction === 'next') {
        swiperRef.value.swiper.slideNext();
        return;
    }

    if (direction === 'prev') {
        swiperRef.value.swiper.slidePrev();
    }
};

const isDisabledPrev = ref(true);
const isDisabledNext = ref(false);
const activeIndex = ref(1);
const isLastSlide = ref(false);
const amountSlides = ref(1);

const onSlideChange = (event) => {
    const [swiper] = event.detail;
    const { isEnd, isBeginning, snapIndex } = swiper;

    isDisabledPrev.value = isBeginning === true;
    isDisabledNext.value = isEnd === true;
    isLastSlide.value = isEnd === true;
    activeIndex.value = snapIndex + 1;
};

/*
    Show pagination
*/
const showPagination = computed(() => {
    if (!isArrayNotEmpty(props.slides)) return false;

    if (swiperRef.value?.swiper?.snapGrid?.length > 1) {
        return true;
    }

    const itemsPerView = isGreaterThanTabletPortrait?.value ? 3 : 2;
    return props.slides.length > itemsPerView;
});

const availableComponents = {
    BlockCardInformation: resolveComponent('BlockCardInformation'),
    BlockListScrollable: resolveComponent('BlockListScrollable'),
    UtilRendererForm: resolveComponent('UtilRendererForm'),
};

const useComponent = (key) => availableComponents[key] || null;

const buildProps = (component, data, isLast) => {
    const originalProps = { ...data };

    if (component === 'UtilRendererForm') {
        originalProps.disableSubmit = !isLast;
        originalProps.successRedirect = isLast
            ? `/${originalProps.successRedirect?.cached_url}` || null
            : null;
    }

    delete originalProps.component;
    delete originalProps._uid;

    return originalProps;
};

watch(() => swiperRef.value, (value) => {
    if (value) {
        amountSlides.value = value.swiper.snapGrid.length;
    }
});

const collectedFormData = ref({});
const onSetInput = (data, identifier) => {
    collectedFormData.value[identifier] = {
        ...collectedFormData.value[identifier],
        ...data,
    };
};

/*
  Succes rediect
  loading state
*/
const isSending = shallowRef(false);
const formRefs = ref([]);
const { translate: translateLink } = useTranslatedStoryblokLinks();

const onSubmit = async () => {
    if (isSending.value) return;
    const formRenderer = formRefs.value[formRefs.value.length - 1];

    try {
        isSending.value = true;
        await props.submitCallback(collectedFormData.value);
        isSending.value = false;
        if (props.successRedirect) {
            await navigateTo(
                translateLink(props.successRedirect),
            );
        }
    } catch (e) {
        useSentryError(e);

        if (formRenderer.value) {
            formRenderer.value.popupType.value = 'error';
            formRenderer.value.popupMessage.value = errorMessage.value;
            formRenderer.value.popupVisible.value = true;
        }
        isSending.value = false;
    }
};
</script>

<style lang="scss" scoped>
.block-slider-slot {
    overflow: hidden;
    width: 100%;
}

.block-slider-slot__container {
    display: flex;
    overflow: visible;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;

    ::part(container) {
        overflow: visible;
    }
}

.block-slider-slot__inner {
    overflow: visible;
}

.block-slider-slot__slide {
    height: auto;
}

.block-slider-slot__swiper {
    display: flex;
    height: 100%;
    flex-direction: column;

    &.mobile {
        display: flex;
        gap: 40px;

        .show-swiper-on-mobile & {
            display: none;
        }
    }

    @include tablet {
        &.mobile {
            display: none;
        }
    }
}

.block-slider-slot__navigation,
.block-slider-slot__links {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 10px;

    &.show-swiper-on-mobile  {
        display: flex;
    }

    @include tablet {
        display: flex;
    }
}

.block-slider-slot__navigation {
    @include fluid-simple('margin-top', 40px, 50px);

    margin-bottom: 55px;
}

.block-slider-slot__swiper-wrapper {
    display: none;

    .show-swiper-on-mobile & {
        display: block;
    }

    @include tablet {
        display: block;
    }
}

.block-slider-slot__button {
    &.is-hidden {
        display: none;
    }
}

.block-slider-slot-link {
    &.is-hidden {
        display: none;
    }
}
</style>
