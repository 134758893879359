<template>
  <div class="provider-form-feedback-job">
    <UtilRendererForm
      v-if="fields?.length > 0"
      :fields="fields"
      :submit-text="submitText"
      :success-redirect="successRedirect?.cached_url"
      :error-message="errorMessage"
      :callback="submitCallback"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Form
    */
    fields: {
        type: Array,
        default: () => [],
    },

    successRedirect: {
        type: [Object],
        default: null,
    },

    errorMessage: {
        type: String,
        default: '',
    },

    submitText: {
        type: String,
        default: '',
    },
});

const recruiter = inject('recruiter', {});
const { locale } = useI18n();
const { create } = useStrapi();
const { fields } = toRefs(props);

const prefillData = useCookie('user_data');
const questionId = useCookie('question_id');

// Prefill feedback based on cookie
if (prefillData.value) {
    fields.value.forEach((field) => {
        if (field.name) {
            // eslint-disable-next-line no-param-reassign
            field.initialValue = prefillData.value[field.name];
        }
    });
}

const submitCallback = async (data) => {
    const body = {
        ...data,
        recruiter: recruiter.value.id,
        answered: false,
        language: locale.value,
        // always set reminder to false cause this is currently not used anymore
        reminder: false,
        frage: questionId.value,
    };

    await create('jobfeedbacks', body);
};
</script>
