<template>
  <div
    class="atom-popover-notification"
    :class="[
      `is-type-${type}`,
      `is-position-${position}`,
    ]"
    @click="emit('on-hide')"
    @keypress="emit('on-hide')"
  >
    <div class="atom-popover-notification__inner">
      <div class="atom-popover-notification__message">
        {{ text }}
      </div>
      <div class="atom-popover-notification__icon">
        <IonIcon
          v-if="icon"
          :icon-name="icon"
          icon-size="12px"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        default: '',
    },

    type: {
        type: String,
        default: 'success',
        validator: (value) => ['success', 'error', 'warning'].includes(value),
    },

    position: {
        type: String,
        default: 'fixed',
    },
});

/*
      Variables
  */
const { type } = toRefs(props);
const emit = defineEmits(['on-hide']);
const icon = ref(null);

watchEffect(() => {
    switch (type.value) {
    case 'success':
        icon.value = 'popup-success';
        break;
    case 'error':
        icon.value = 'popup-error';
        break;
    default:
        icon.value = null;
        break;
    }
});

onMounted(() => {
    window.setTimeout(() => {
        emit('on-hide');
    }, 4000);
});
</script>

<style lang="scss" scoped>
.atom-popover-notification {
    @include z-index('formNotification');

    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 944px;
    padding-right: var(--page-padding);
    padding-left: var(--page-padding);
    margin: auto;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &.is-position-absolute {
        position: absolute;
        top: unset;
        right: 0;
        bottom: 100px;
        left: 0;
        margin: auto;
    }
}

.atom-popover-notification__inner {
    @include fluid-simple('padding-left', 30px, 80px);
    @include fluid-simple('padding-right', 30px, 80px);
    @include fluid-simple('padding-top', 25px, 35px);
    @include fluid-simple('padding-bottom', 25px, 35px);

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background: var(--c-white);
    box-shadow: var(--box-shadow--small);
    column-gap: 20px;
    font-family: var(--f-family--secondary);
    font-weight: var(--f-weight--regular);
    pointer-events: none;

    .is-type-error & {
        color: var(--c-error);
    }

    .is-type-success & {
        color: var(--c-success);
    }

    .is-type-warning & {
        color: var(--c-warning);
    }
}

.atom-popover-notification__icon {
    display: flex;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    .is-type-error & {
        background: var(--c-error);
    }

    .is-type-success & {
        background: var(--c-success);
    }

    .is-type-warning & {
        background: var(--c-warning);
    }
}
</style>
