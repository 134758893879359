<template>
  <div class="atom-input atom-input-radio">
    <div
      v-for="option in options"
      :key="option.value"
      class="atom-input atom-input-radio__wrapper"
      :class="{
        'is-disabled': isDisabled,
        'has-error': showErrors,
      }"
      @focusin="setHover(true)"
      @focus="setFocus(true)"
      @focusout="setFocus(false)"
    >
      <div
        class="atom-input-radio__box"
        :class="{ 'is-active': inputValue === option.value }"
      />

      <input
        :id="option.value"
        v-model="inputValue"
        :value="option.value"
        type="radio"
        tabindex="-1"
        class="atom-input-radio__input"
      />

      <label
        class="atom-input-radio__label"
        :for="option.value"
      >
        <AtomTextRichtext
          :html="option.label"
          class="atom-input-radio__label"
          :text-color="color"
        />
      </label>
    </div>
    <transition name="fade-in-slow">
      <AtomInputErrors
        v-if="showErrors"
        :errors="errors"
        :is-extended="showErrorList"
        :is-checkbox="false"
      />
    </transition>
  </div>
</template>

<script setup>
const props = defineProps({
    options: {
        type: Array,
        required: true,
        validator: (value) => Array.isArray(value)
                && value.every(
                    (option) => typeof option === 'object'
                        && 'value' in option
                        && 'label' in option,
                ),
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: [String, Number],
        default: '',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Styles
    */
    color: {
        type: String,
        default: 'var(--c-black)',
    },
});

const { color } = toRefs(props);

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref(props.initialValue || '');

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error', 'on-reset']);

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (props.isDisabled) return;

    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }

    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};
watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

onMounted(() => {
    handleData(inputValue.value);
});
/*
    States
*/
const {
    setHover,
    setFocus,
    showErrors,
    showErrorList,
} = useFormFieldStates(inputValue, errors, props);

const resetValue = () => {
    isResetting.value = true;
    inputValue.value = props.initialValue || '';
    emit('on-reset');
};

/*
    Watch showErrors
*/
watch(() => showErrors.value, (newValue) => {
    if (newValue) {
        setHover(true);
    }
});

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input-radio {
    display: flex;
    width: 100%;
    gap: 20px;
}

.atom-input-radio__wrapper {
    @include formFieldWrapper;
    @include fluid('--checkbox-size', 23px, 23px, 23px);

    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--c-grey);
    color: var(--color);
    column-gap: 10px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--a);
    font-weight: var(--f-weight--regular);
    line-height: var(--l-height--a);

    --color: v-bind(color);

    .block-registration-newsletter & {
        margin-top: 10px;
    }

    &.is-disabled {
        cursor: default;
        opacity: 0.2;
        pointer-events: none;
    }
}

.atom-input-radio__input {
    position: absolute;
    width: var(--checkbox-size);
    min-width: var(--checkbox-size);
    height: var(--checkbox-size);
    cursor: pointer;
    opacity: 0;

    .is-disabled & {
        cursor: default;
    }
}

.atom-input-radio__box {
    position: relative;
    width: var(--checkbox-size);
    min-width: var(--checkbox-size);
    height: var(--checkbox-size);
    border: 2px solid var(--color);
    border-radius: 4px;
    pointer-events: none;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 2px;
        border-radius: 50px;
        background: var(--color);
        content: '';
        opacity: 0;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: opacity 0.1s ease-in-out;
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 2px;
        border-radius: 50px;
        background: var(--color);
        content: '';
        opacity: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: opacity 0.1s ease-in-out;
    }

    &.is-active {
        &:after {
            opacity: 1;
        }

        &:before {
            opacity: 1;
        }
    }
}

.atom-input-radio__label {
    margin-top: -1px;
    cursor: pointer;
    user-select: none;

    ::v-deep(.atom-richtext__inner) {
        font-size: var(--f-size--a);
        font-weight: var(--f-weight--regular);
        line-height: var(--l-height--a);
    }

    .is-disabled & {
        cursor: default;
    }
}
</style>
