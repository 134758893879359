<template>
  <component :is="tag" class="main-content">
    <NuxtLazyHydrate
      v-for="(item, lcpIndex) of data"
      :key="item._uid"
      v-bind="getHydrationProp(item.component)"
      @hydrated="onHydrated(item.component)"
    >
      <component
        :is="useComponent(item.component)"
        v-editable="item"
        v-bind="buildProps(item.component, item, lcpIndex)"
      />
    </NuxtLazyHydrate>
  </component>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },

    disableGrid: {
        type: Boolean,
        default: false,
    },

    tag: {
        type: String,
        default: 'main',
    },
});

/*
  Here you can add additional mappings for your storyblok
  components to match the cms agnostic components
*/
const bypassLazyHydration = inject('isStoryblokPreview', false);

const buildProps = (component, data, lcpIndex) => {
    const originalProps = { ...data };

    /*
      AtomImage
    */
    if (component === 'AtomImage') {
        originalProps.provider = 'storyblok';
        originalProps.width = 1500;
        originalProps.lazy = lcpIndex < 3 ? false : originalProps.lazy;
        originalProps.preload = lcpIndex < 3;
        originalProps.sizes = 'xs:320px sm:480px md:750px lg:1200px xl:1500px';
    }

    if (component === 'AtomTextRichtext') {
        originalProps.html = isPolite.value
            ? originalProps.politeHtml || originalProps.html : originalProps.html;
        originalProps.fontSize = originalProps.isSmall ? 'var(--f-size--h4)' : 'var(--f-size--p)';
        originalProps.lineHeight = originalProps.isSmall ? 'var(--l-height--h4)' : 'var(--l-height--p)';

        delete originalProps.politeHtml;
        delete originalProps.isSmall;
    }

    if (component === 'AtomTextHeading') {
        originalProps.text = isPolite.value
            ? originalProps.politeText || originalProps.text : originalProps.text;
        originalProps.pretitle = isPolite.value
            ? originalProps.politePretitle || originalProps.pretitle : originalProps.pretitle;

        delete originalProps.politeText;
        delete originalProps.politePretitle;
    }

    if (component === 'BlockCardInformation') {
        originalProps.text = isPolite.value
            ? originalProps.politeText || originalProps.text : originalProps.text;
        originalProps.title = isPolite.value
            ? originalProps.politeTitle || originalProps.title : originalProps.title;

        delete originalProps.politeText;
        delete originalProps.politeTitle;
    }

    if (component === 'BlockSwiperSlot') {
        originalProps.slides = originalProps.slides?.map((slide) => ({
            ...slide,
            image: {
                ...slide.image,
                src: slide.image.filename,
            },
        }));
    }

    if (component === 'BlockListCards') {
        originalProps.items = originalProps.items?.map((item) => ({
            ...item,
            icons: item.icons?.map((icon) => ({
                ...icon,
                src: icon.src.cached_url,
            })),
        }));
    }

    if (component === 'BlockListRichtext') {
        originalProps.items = originalProps.items?.map((item) => ({
            ...item,
            text: isPolite.value
                ? item.politeHtml || item.html : item.html,
        }));
    }

    /* Set the on grid prop to true */
    if (!props.disableGrid) {
        originalProps.isOnGrid = true;
    }

    /* Remove storyblok system props for cleaner markup */
    delete originalProps.component;
    delete originalProps._uid;

    return originalProps;
};

/*
  Add more components here if you need them in the storybook render loop
  Remember to also add the component name to the hydrate functions below
*/
const availableComponents = {
    // Atoms
    AtomTextHeading: resolveComponent('AtomTextHeading'),
    AtomTextRichtext: resolveComponent('AtomTextRichtext'),
    AtomImage: resolveComponent('AtomImage'),
    AtomButton: resolveComponent('AtomButton'),

    // Blocks
    BlockCardInformation: resolveComponent('BlockCardInformation'),
    BlockSwiperSlot: resolveComponent('BlockSwiperSlot'),
    BlockListScrollable: resolveComponent('BlockListScrollable'),
    BlockListCards: resolveComponent('BlockListCards'),
    BlockListRichtext: resolveComponent('BlockListRichtext'),

    // Providers
    ProviderFormQuestions: resolveComponent('ProviderFormQuestions'),
    ProviderFormFeedbackInterview: resolveComponent('ProviderFormFeedbackInterview'),
    ProviderFormFeedbackJob: resolveComponent('ProviderFormFeedbackJob'),
};

const useComponent = (key) => availableComponents[key] || null;

/*
  Hydration management
*/
const hydrateInstant = [
    'BlockSwiperSlot',
];

const hydrateOnInteraction = [
];

const hydrateOnVisible = [
    'ProviderFormQuestions',
    'ProviderFormFeedbackJob',
    'ProviderFormFeedbackInterview',
];

const hydrateNever = [
    'AtomImage',
    'AtomTextHeading',
    'AtomTextRichtext',
];

// Careful with this one, on safari there is no idle callback so it takes 6 seconds to hydrate
const hydrateOnIdle = [];

const getHydrationProp = (componentName) => {
    if (bypassLazyHydration || hydrateInstant.includes(componentName)) {
        return { whenTriggered: true };
    }

    if (hydrateOnIdle.includes(componentName)) {
        return { whenIdle: true };
    }

    if (hydrateOnInteraction.includes(componentName)) {
        return {
            onInteraction: [
                'touchstart',
                'focus',
                'mouseenter',
            ],
        };
    }

    if (hydrateOnVisible.includes(componentName)) {
        return { whenVisible: { rootMargin: '50px' } };
    }

    if (hydrateNever.includes(componentName)) {
        return { never: true };
    }

    return {};
};

const debug = false;
const onHydrated = (componentName) => {
    if (!debug) return;
    // eslint-disable-next-line no-console
    console.log(`Component ${componentName} hydrated`);
};
</script>

<style lang="scss">
.main-content {
    & > * {
        &:last-child {
            margin-bottom: 0;

            &.is-on-grid {
                margin-bottom: 0;
            }
        }
    }
}
</style>
