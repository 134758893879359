<template>
  <div class="block-card-image">
    <AtomImage
      class="block-card-image__image"
      :image="image"
      scaling-direction="height"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Object,
        required: true,
    },

    backgroundColor: {
        type: String,
        default: 'transparent',
    },

    borderRadius: {
        type: String,
        default: '0',
    },
});

const {
    backgroundColor,
    borderRadius,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.block-card-image {
    @include fluid-simple('padding-top', 25px, 33px);
    @include fluid-simple('padding-bottom', 25px, 33px);

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: v-bind(borderRadius);
    background-color: v-bind(backgroundColor);
}

.block-card-image__image {
    height: 100%;
}
</style>
