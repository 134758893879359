<template>
  <div class="block-list-scrollable">
    <BlockCardImage
      v-if="image"
      :image="image"
      :background-color="`var(--c-grey)`"
      :border-radius="`10px`"
    />

    <div class="block-list-scrollable__title-text-wrapper">
      <div class="block-list-scrollable__title">
        {{ title }}
      </div>
      <AtomTextRichtext
        class="block-list-scrollable__text"
        :html="text"
        font-size="var(--f-size--h4)"
        line-height="var(--l-height--h4)"
      />
    </div>

    <div class="block-list-scrollable__swiper-wrapper">
      <div
        v-if="isArrayNotEmpty(items)"
        ref="scrollableContainerRef"
        class="block-list-scrollable__swiper"
        :class="{ grabbing: isGrabbing }"
        @scroll="checkScroll($event)"
        @mousedown="onMouseDown"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
        @mouseleave="onMouseUp"
        @focus="onFocus"
        @blur="onBlur"
      >
        <div class="block-list-scrollable__item-wrapper">
          <div
            v-for="(item, index) of items"
            :key="`item-${index}`"
            class="block-list-scrollable__item"
          >
            <IonIcon icon-name="line" icon-size="11px" />
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="block-list-scrollable__button-wrapper">
        <Transition name="fade-in">
          <button
            v-if="showUpArrow"
            class="block-list-scrollable__button--up"
            type="button"
            @click="scrollOnClick('up')"
          >
            <IonIcon icon-name="arrow-up" icon-size="37px" />
          </button>
        </Transition>
        <Transition name="fade-in">
          <button
            v-if="showDownArrow"
            class="block-list-scrollable__button--down"
            type="button"
            @click="scrollOnClick('down')"
          >
            <IonIcon icon-name="arrow-down" icon-size="37px" />
          </button>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    image: {
        type: Object,
        required: true,
    },

    title: {
        type: String,
        required: true,
    },

    text: {
        type: String,
        required: true,
    },

    items: {
        type: Array,
        required: true,
    },
});

const scrollableContainerRef = ref(null);
const showUpArrow = shallowRef(false);
const showDownArrow = shallowRef(true);

const checkScroll = (event) => {
    const container = event.target;
    const atTop = container.scrollTop === 0;
    const atBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
    showUpArrow.value = !atTop;
    showDownArrow.value = !atBottom;
};

const scrollOnClick = (direction) => {
    const container = scrollableContainerRef.value;
    if (direction === 'up') {
        container.scrollBy({ top: -100, behavior: 'smooth' });
    } else if (direction === 'down') {
        container.scrollBy({ top: 100, behavior: 'smooth' });
    }
};

const isDragging = shallowRef(false);
const startY = shallowRef(0);
const scrollTop = shallowRef(0);
const isGrabbing = shallowRef(false);

const onMouseDown = (event) => {
    isDragging.value = true;
    startY.value = event.pageY - scrollableContainerRef.value.offsetTop;
    scrollTop.value = scrollableContainerRef.value.scrollTop;
    isGrabbing.value = true;
};

const onMouseMove = (event) => {
    if (!isDragging.value) return;
    const y = event.pageY - scrollableContainerRef.value.offsetTop;
    const walk = (y - startY.value) * 2;
    scrollableContainerRef.value.scrollTop = scrollTop.value - walk;
};

const onMouseUp = () => {
    isDragging.value = false;
    isGrabbing.value = false;
};

const onFocus = () => {
    isGrabbing.value = true;
};

const onBlur = () => {
    isGrabbing.value = false;
};

const isArrayNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;

const updateArrowsVisibility = () => {
    const container = scrollableContainerRef.value;
    if (container) {
        const atTop = container.scrollTop === 0;
        const atBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
        const isOverflowing = container.scrollHeight > container.clientHeight;
        showUpArrow.value = !atTop && isOverflowing;
        showDownArrow.value = !atBottom && isOverflowing;
    }
};

onMounted(() => {
    updateArrowsVisibility();
});
</script>

<style lang="scss" scoped>
.block-list-scrollable {
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
    color: var(--c-black);
    gap: 20px;

    .block-card-image {
        max-height: 190px;
    }
}

.block-list-scrollable__title-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.block-list-scrollable__title {
    font-family: var(--f-family--secondary-bold);
    font-size: 17px;
}

.block-list-scrollable__item {
    display: flex;
    padding: 12px 0 12px 6px;
    border-top: 1px solid #000000;
    font-family: var(--f-family--secondary);
    font-size: var(--f-size--a);
    gap: 20px;
    line-height: var(--l-height--a);

    .ion-icon {
        margin-top: 6px;
    }
}

.block-list-scrollable__swiper-wrapper {
    display: flex;
    height: 100%;
    gap: 10px;

    @include tablet {
        overflow: hidden;
        height: 378px;
    }
}

.block-list-scrollable__swiper {
    @include remove-scrollbars();

    width: 100%;
    height: 100%;

    @include tablet {
        overflow: scroll;
        cursor: grab;
        user-select: none;

        &.grabbing {
            cursor: grabbing;
        }
    }
}

.block-list-scrollable__button-wrapper {
    position: relative;
    display: none;
    overflow: visible;
    width: 23px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
        display: flex;
    }
}

.block-list-scrollable__button {
    &--up {
        position: absolute;
        top: 0;
    }

    &--down {
        position: absolute;
        bottom: 0;
    }
}
</style>
