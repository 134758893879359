<template>
  <div
    ref="wrapperRef"
    class="atom-input atom-input__wrapper"
  >
    <div
      class="atom-input-rating"
    >
      <input
        :id="name"
        v-model="inputValue"
        :name="name"
        tabindex="-1"
        class="atom-input-rating__input"
        @focus="setFocus(true)"
        @focusout="setFocus(false)"
      >
    </div>

    <div
      ref="optionsRef"
      class="atom-input-rating__options"
    >
      <div
        v-for="option, index in options"
        :key="option.value"
        class="atom-input-rating__option"
        :class="{
          'is-selected': selectedOption === option.value,
        }"
      >
        <button
          type="button"
          class="atom-input-rating__option-button"
          @click="selectOption(option)"
        >
          <span class="atom-input-rating__option-label">{{ option.label }}</span>
        </button>

        <div class="atom-input-rating__option-text-wrapper">
          <div v-if="index === 0" class="atom-input-rating__option-text">
            <span>{{ textLeft }}</span>
          </div>
          <div v-if="index === options.length - 1" class="atom-input-rating__option-text">
            <span>{{ textRight }}</span>
          </div>
        </div>
      </div>

      <transition name="fade-in-slow">
        <AtomInputErrors
          v-if="showErrors"
          :errors="errors"
          :is-extended="showErrorList"
        />
      </transition>
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides a select input field with label, icon, and validation support.
 *
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {array} [props.options=[]] - An array of options to display in the select field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */
const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        default: '',
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: [String, Number],
        default: '',
    },

    textLeft: {
        type: String,
        default: '',
    },

    textRight: {
        type: String,
        default: '',
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },
});

/*
    Intialze formfield
*/
const wrapperRef = ref(null);
const errors = ref([]);
const inputValue = ref('');
const selectedOption = ref(null);

const setInitialValue = () => {
    inputValue.value = props.initialValue;
    selectedOption.value = props.initialValue;
};

setInitialValue();

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error', 'on-reset']);

/* Watch input */
const isResetting = ref(false);
const handleData = (value) => {
    if (isResetting.value) {
        errors.value = [];
        isResetting.value = false;
        return;
    }
    const { validationErrors } = useFormfieldEmit(
        value,
        props.name,
        emit,
        props.validations,
    );

    errors.value = validationErrors;
};

watch(() => inputValue.value, (newValue) => {
    handleData(newValue);
});

handleData(inputValue.value);

/*
    States
*/
const {
    setHover,
    setFocus,
    showErrors,
    showErrorList,
} = useFormFieldStates(inputValue, errors, props);

/*
    Custom Logic
*/
const optionsRef = ref(null);

/* handle selected options */
const selectOption = (option) => {
    selectedOption.value = option.value;
    inputValue.value = option.value;
};

/*
    Reset value
*/
const resetValue = () => {
    isResetting.value = true;
    selectedOption.value = null;
    inputValue.value = '';
    emit('on-reset');
};

/*
    Watch showErrors
*/
watch(() => showErrors.value, (newValue) => {
    if (newValue) {
        setHover(true);
    }
});

defineExpose({
    resetValue,
});
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;
    position: relative;
    width: 100%;
    max-width: 910px;
}

.atom-input-rating {
    @include fluid-simple(--horizontal-padding, 20px, 24px);
    @include formFieldBasicsText;

    display: none;
}

.atom-input-rating__label {
    @include formFieldLabelText;
}

.atom-input-rating__input {
    @include formFieldInputText;

    pointer-events: none;
}

.atom-input-rating__icon {
    @include formFieldInputIcon(false);

    transition: transform 0.5s var(--ease-in-out--back);

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-input-rating__trigger {
    @include fluid-simple('height', 70px, 80px);
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-input-rating__options {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: top;
    justify-content: space-between;
}

.atom-input-rating__option-label {
    @include fluid-simple('line-height', 70px, 80px);
    @include z-index('selectLabel');

    position: relative;
    color: var(--c-secondary);
    font-family: var(--f-family--primary);
    font-size: 28px;
    transition: all 0.2s ease-in-out;

    .is-selected & {
        color: var(--c-white);
    }

    @include desktop {
        font-size: 46px;
    }
}

.atom-input-rating__option-text {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 20px;
    font-family: var(--f-family--secondary);
    font-size: var(--f-size--h4);
    text-align: left;
    white-space: nowrap;

    span {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

}

.atom-input-rating__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @include desktop {
        gap: 20px;
    }

    &:last-child {
        .atom-input-rating__option-text {
            text-align: right;
            span {
                right: 0;
                left: unset;
            }
        }
    }
}

.atom-input-rating__option-button {
    @include fluid-simple('width', 52px, 115px);
    @include fluid-simple('height', 52px, 115px);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--c-secondary);
    border-radius: 50%;
    background-color: var(--c-grey);
    transition: all 0.2s ease-in-out;

    .is-selected & {
        border: 1px solid var(--c-primary);
        background-color: var(--c-primary);
        color: var(--c-white);
    }

    @include only-hover {
        background-color: var(--c-secondary);
        color: var(--c-white);

        .atom-input-rating__option-label {
            color: var(--c-white);
        }
    }
}

.atom-input-rating__option-text-wrapper {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 20px;
}

</style>
